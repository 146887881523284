<mat-sidenav-container class="example-container menuBar ">
    <mat-sidenav mode="side" closed>Sidenav content</mat-sidenav>
    <mat-sidenav-content *ngIf="this.userRole == 'Doctor'">
        <a [routerLink]="['dashboard']" class="nav-link menuMarging" mat-menu-item href="#">
            <img class="menuImg" src="/assets/img/DASHBOARD.png" />
        </a>
    </mat-sidenav-content>
    <mat-sidenav-content>
        <a class="nav-link menuMarging" [routerLink]="['members']" mat-menu-item href="#">
            <img class="menuImg" src="/assets/img/All Members Inactive.png" />
        </a>
    </mat-sidenav-content>

    <mat-sidenav-content>
        <a class="nav-link menuMarging" [routerLink]="['teams']" mat-menu-item href="#">
            <img class="menuImg" src="/assets/img/Group 482427.png" />
        </a>
    </mat-sidenav-content>
    <mat-sidenav-content>
        <a class="nav-link menuMarging" mat-menu-item href="{{oldDhruva}}calender-event">
            <img class="menuImg" src="/assets/img/Calender Inactive.png" />
        </a>
    </mat-sidenav-content>

    <mat-sidenav-content *ngIf="this.userRole == 'Nutritionist' || this.userRole == 'Administrator'">
        <a class="nav-link menuMarging" [routerLink]="['meal-plans']" mat-menu-item href="#">
            <img class="menuImg" src="/assets/img/meal.png" />
        </a>
    </mat-sidenav-content>
    <mat-sidenav-content *ngIf="this.userRole == 'Fitness Instructor' || this.userRole == 'Administrator'">
        <a class="nav-link menuMarging" [routerLink]="['fitness-plan']" mat-menu-item href="#">
            <img class="menuImg" src="/assets/img/fit.png" />
        </a>
    </mat-sidenav-content>
</mat-sidenav-container>