import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { NgFor } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ToastService } from '../../services/toast.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  formatDate
}
  from '@angular/common';
import { NgIf } from '@angular/common';
import { Router } from "@angular/router";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FitnessPlanService } from '../fitness-plan.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-plan',
  standalone: true,
  imports: [NgxMaterialTimepickerModule, MatProgressSpinnerModule, NgIf, FormsModule, NgClass, NgFor, MatDateRangeInput
    , MatDatepickerModule, MatFormFieldModule, MatLabel, MatHint, MatNativeDateModule, MatInputModule,
    NgxFileDropModule],
  templateUrl: './upload-plan.component.html',
  styleUrl: './upload-plan.component.css'
})

export class UploadPlanComponent {

  isLoading: any = 2;
  memberId: any;
  vital: any = {};
  memberbasic: any = {};
  activeReports: any = [];
  inActiveReports: any = [];
  modalDocumentURL: SafeResourceUrl;
  files: NgxFileDropEntry[] = [];
  constructor(private service: FitnessPlanService, private route: ActivatedRoute,
    private router: Router, private toast: ToastService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('memberId');
    this.service.getMemberDetails(this.memberId).subscribe((res: any) => {
      this.memberbasic = res.result;
    });
    this.service.getVital(this.memberId).subscribe((res: any) => {
      this.vital = res.result;
    });
    this.callReportsApi();
  }
  viewReport(doc: any) {
    console.log(doc);
    this.modalDocumentURL = this.sanitizer.bypassSecurityTrustResourceUrl(doc);
  }

  callReportsApi() {
    this.service.getAllReports(this.memberId).subscribe((res: any) => {
      this.activeReports = res.fitnessPlanReports.filter((x: any) => x.isActive == true);
      let inActive = res.fitnessPlanReports.filter((x: any) => x.isActive == false);
      inActive = inActive.sort((a: any, b: any) => new Date(b.assignedDate).getTime() - new Date(a.assignedDate).getTime());
      for (let i = 0; i < inActive.length; i += 3) {
        this.inActiveReports.push(inActive.slice(i, i + 3));
      }
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    console.log(files.length);
    if (files.length > 1) {
      this.toast.errorMessage('You can upload only one file at a time!');
      return;
    }
    let droppedFile = files[0];
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.callUploadApi(file);
      });
    } else {
      this.toast.errorMessage('You can upload only files!');
    }
  }
  onFileSelected(ev: any) {
    const files = ev.target.files;
    this.callUploadApi(files[0]);
  }
  callUploadApi(file: any) {
    console.log(file);
    console.log(file.type);
    if (file.type != 'application/pdf') {
      this.toast.errorMessage('You can upload only pdf!');
      return;
    }
    const formData = new FormData()
    if (file.size > 1 * 1024 * 1024) {
      this.toast.errorMessage('File size should not be more than 1MB!');
      return;
    }
    formData.append('pdfFile', file, file.name)
    formData.append('memberid', this.memberId);
    this.isLoading = 1;
    this.service.uploadPlan(formData).subscribe((res: any) => {
      this.isLoading = 2;
      if (res == 1) {
        this.toast.errorMessage('Some Error Occured! Please try again.');
      } else {
        this.toast.successMessage('Assigned to ' + this.memberbasic.firstName + " " + this.memberbasic.lastName);
        this.inActiveReports = [];
        this.callReportsApi();
      }
    });
    let d = document.getElementById('fileInput') as HTMLInputElement;
    d.value = '';
  }
  dateFormat(date: any) {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }
  handleBackBtn() {
    this.router.navigate(['/fitness-plan']);
  }
  reAssignFP(id: any) {
    let data = { "memberId": this.memberId, "id": id };
    this.service.reAssignFP(data).subscribe((res: any) => {
      this.toast.successMessage('Assigned to ' + this.memberbasic.firstName + " " + this.memberbasic.lastName);
      this.inActiveReports = [];
      this.callReportsApi();
    });
  }
}
