<div class="mainTitle">Fitness Plans</div>
<div class="row mt-5 textAlign">
  <input matInput type="textbox" placeholder="Search Members" class="form-control textField" [(ngModel)]="name"
    (keyup)="searchByMember($event)" />
</div>
<div class="subTitle mt-5">All Members</div>
<ng-container *ngFor="let rec of memberList;">
  <div class="row mt-5 mr-3">
    <div class="col-sm-4" *ngFor="let r of rec;">
      <app-basic-info [member]="r"></app-basic-info>
    </div>
  </div>
</ng-container>
<div class="row mt-3 justify-content-center">
  <mat-paginator #paginator (page)="pageChangeEvent($event)" [pageSize]="10" [pageIndex]="0"
    [length]="this.resp?.members?.metadata?.totalCount" showFirstLastButton>
  </mat-paginator>
</div>