<div class="memberPattern">
  <div class="row flex">
    <div class="col-lg-2"><img class="patientImg" mat-card-image src={{imgSrc}} /></div>
    <div class="col-lg-6 name mt-1">
      {{member.fullName}}
    </div>
    <div class="col-lg-4">
      <img src={{imgAssignedUnassigned}} width="110" height="35" />
    </div>
  </div>
  <div class="row flex age">
    <div class="mt-1">
      <span> Age: {{member.age}}</span> <span class="htWt"> Height: {{ht}} CM</span><span class="htWt"> Weight: {{wt}}
      </span>
    </div>
  </div>
  <div class="row date flex mt-3">
    Last Assigned Date: &nbsp;<span class="date1">{{date}}</span><span class="htWt"> Gender :  {{member.gender}}</span>
  </div>
  <div class="row date flex mt-3">
   Team: &nbsp;<span class="date1">{{teamName}}</span>
  </div>
  
  <div class="row flex mt-5">
    <div class="col-sm-6"></div>
    <div class="col-sm-6">
      <a class="btn btnFirst btnLabel" [routerLink]="['/upload-plan']" [queryParams]="{ memberId:member.id}"
        href="#">Upload</a>
    </div>
  </div>
</div>