import { Component, ViewChild } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { FitnessPlanService } from './fitness-plan.service';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { NgForOf } from '@angular/common';
import { CommonModule, formatDate, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';


@Component({
  selector: 'app-fitness-plan',
  standalone: true,
  imports: [MatListModule, NgForOf, NgIf, CommonModule, FormsModule, MatPaginator, MatPaginatorModule, BasicInfoComponent],
  templateUrl: './fitness-plan.component.html',
  styleUrl: './fitness-plan.component.css'
})
export class FitnessPlanComponent {

  constructor(private service: FitnessPlanService) { }
  @ViewChild('paginator') paginator: MatPaginator;
  memberList: any = [];
  countRes: any = [];
  filter: any = 'all';
  timeout: any = null;
  name: any = '';
  resp: any = [];
  previousPageSize: any = 10;
  pageIndex: any = 1;
  ngOnInit(): void {
    this.getFitnessPlans(this.pageIndex, 10, this.name);
  }
  searchByMember(event: any) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 1;
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 500);
  }
  executeListing(searchValue: any) {
    this.name = searchValue;
    this.memberList = [];
    this.getFitnessPlans(this.pageIndex, this.paginator.pageSize, this.name);
  }

  pageChangeEvent(page: any) {
    if (page.pageSize != this.previousPageSize) {
      this.paginator.pageIndex = 0;
    }
    this.previousPageSize = page.pageSize;
    this.pageIndex = this.paginator.pageIndex + 1;
    this.getFitnessPlans(this.pageIndex, this.paginator.pageSize, this.name);
  }

  getFitnessPlans(pageNo: number, pageSize: number, name: any) {
    {
      this.memberList = [];
      if (name == '') {
        this.service.getFPAllMembers(pageNo, pageSize).subscribe(response => {
          this.resp = response;
          this.populateList();
        });
      } else {
        this.service.searchFPPerMember(pageNo, pageSize, name).subscribe(response => {
          this.resp = response;
          this.populateList();
        });
      }
    }
  }

  populateList() {
    let list = this.resp.members.data;
    for (let i = 0; i < list.length; i += 3) {
      this.memberList.push(list.slice(i, i + 3));
    }
  }
}
