import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf, CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import {
  formatDate
}
  from '@angular/common';
@Component({
  selector: 'app-basic-info',
  standalone: true,
  imports: [NgIf, CommonModule, RouterLink],
  templateUrl: './basic-info.component.html',
  styleUrl: './basic-info.component.css'
})
export class BasicInfoComponent {
  @Input() member: any;
  ht: any;
  wt: any = "---";
  date: any = '---';
  imgSrc: any = "assets/img/defaultUser.png";
  imgAssignedUnassigned: any = "assets/img/unassigned.png";
  teamName: string = "---";
  ngOnInit(): void {
    this.ht = Math.trunc(this.member.height);
    if (this.member.weight) {
      this.wt = Math.trunc(this.member.weight).toString() + " KG";
    }
    if (this.member.profilePicture && this.member.profilePicture != '') {
      this.imgSrc = this.member.profilePicture;
    }
    if (this.member.fitnessPlanAssignedDate) {
      let d = this.member.fitnessPlanAssignedDate.split('T')[0];
      d = d.split('-');
      this.date = formatDate(new Date(d[0], d[1] - 1, d[2]), 'dd MMM yyyy', 'en-US');
      this.imgAssignedUnassigned = "assets/img/assigned.png";
    }
    if (this.member.teamName) {
      this.teamName = this.member.teamName;
    } else {
      this.teamName = "---";
    }
  }
}
