import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class FitnessPlanService {

  private apiBaseUrl: string;

  constructor(private http: HttpClient, private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
  }

  getMemberDetails(id: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.members + '/GetMemberById?memberId=' + id;
    return this.http.get(url, { headers });
  }

  getVital(id: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.vital + '?memberId=' + id + '&date=0001-01-01T00:00:00';
    return this.http.get(url, { headers });
  }

  getFPAllMembers(page: any, pageSize: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    let user = this.sharedService.getLoggedInUser();
    const url = this.apiBaseUrl + ControllerApiPath.fitnessPlan + '/getallmembers?page=' + page + '&pageSize=' + pageSize + '&loggedUserId=' + user.id;
    return this.http.get(url, { headers });
  }
  getAllReports(memberId: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.fitnessPlan + '/getallreports?memberId=' + memberId;
    return this.http.get(url, { headers });
  }
  searchFPPerMember(page: any, pageSize: any, searchTerm: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    let user = this.sharedService.getLoggedInUser();
    const url = this.apiBaseUrl + ControllerApiPath.fitnessPlan + '/searchunassignedmemebrs?page=' + page + '&pageSize=' + pageSize + '&loggedUserId=' + user.id + '&searchTerm=' + searchTerm;
    return this.http.get(url, { headers });
  }
  reAssignFP(data: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.fitnessPlan + '/reassign'
    return this.http.post(url, data, { headers });
  }
  uploadPlan(data: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.fitnessPlan + '/assign';
    return this.http.post(url, data, { headers }).pipe(
      map((response) => response),
      catchError((error) => {
        console.log(error);
        return of(1);
      }),
    );
  }
}
