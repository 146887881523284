<div id="wait" *ngIf="isLoading == 1">
    <mat-spinner></mat-spinner>
</div>
<div class="row">
    <img class="imgBack" (click)="handleBackBtn()" style="cursor: pointer;" src="assets/img/backbtn.png" />
</div>
<div class="col-md-12 title mt-5">
    {{memberbasic.firstName}} {{memberbasic.lastName}}
</div>
<div class="col-md-12 mt-2">
    <span class="spanContent">{{memberbasic.plan?memberbasic.plan : 'No Plan Found'}}</span> &nbsp; | &nbsp; <span
        class="spanTitle">Age:</span>&nbsp;<span class="spanContent">{{memberbasic.age}}</span> &nbsp; | &nbsp; <span
        class="spanTitle">DOB:</span>&nbsp;<span
        class="spanContent">{{memberbasic.dateOfBirth?memberbasic.dateOfBirth.split('T')[0]:''}}</span> &nbsp; | &nbsp;
    <span class="spanTitle">Height:</span>&nbsp;<span class="spanContent">{{memberbasic.height}} CM</span> &nbsp; |
    &nbsp; <span class="spanTitle">Weight:</span>&nbsp;<span
        class="spanContent">{{vital && vital.bmiData?vital.bmiData.weight+" KG":'---'}}</span> &nbsp; | &nbsp; <span
        class="spanTitle">Level of Care:</span>&nbsp;<span
        class="spanContent">{{memberbasic.memberLevel?memberbasic.memberLevel:'---'}}</span> &nbsp; | &nbsp; <span
        class="spanTitle">BMI:</span>&nbsp;<span
        class="spanContent">{{vital && vital.bmiData?vital.bmiData.bmi+"  Kg/m":'---'}}</span>
</div>
<div class="fileDropContainer" (click)="fileInput.click()">
    <input #fileInput id="fileInput" type="file" style="display: none;" (change)="onFileSelected($event)" />
    <ngx-file-drop (onFileDrop)="dropped($event)" [dropZoneClassName]="'fileDropContainer'"
        [contentClassName]="'fileDropContent'">
        <ng-template ngx-file-drop-content-tmp>
            <img src="assets/img/uploadfp.png" height="40" width="40" style="margin-top: 70px;" />
            <p class="dropContent mt-3">Click to upload your pdf here or Drag and drop your file here</p>
        </ng-template>
    </ngx-file-drop>
</div>
<div class="row mt-5">
    <div class="col-md-12">
        <div class="subTitle">Currently Assigned</div>
    </div>
</div>
<div  class="row mt-2 ml-1" *ngIf="activeReports.length==0">No Plan Assigned</div>
<div class="row mt-4" *ngIf="activeReports.length>0">
    <div class="col-sm-4">
        <div class="pattern">
            <div class="row flex">
                <div class="col-lg-2"><img class="pdfImg" mat-card-image src="assets/img/pdf.png" /></div>
                <div class="col-lg-9 mt-1 ml-2">
                    <div class="row reportName">{{activeReports[0]?.reportUrl.split('/').pop().replace('.pdf', '')}}
                    </div>
                    <div class="row spanContent mt-2">Assigned on: {{activeReports[0]?.assignedDate.split('T')[0]}}
                    </div>
                </div>
            </div>
            <div class="row flex mt-5">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <a class="btn btnFirst btnLabel" data-toggle="modal" data-target="#reportModal"
                        (click)="viewReport(activeReports[0]?.reportUrl)">View</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12">
        <div class="subTitle">History</div>
    </div>
</div>
<div  class="row mt-2 ml-1" *ngIf="inActiveReports.length==0">No History Found</div>
<ng-container *ngFor="let rec of inActiveReports;">
    <div class="row mt-4">
        <div class="col-sm-4" *ngFor="let r of rec;">

            <div class="pattern">
                <div class="row flex">
                    <div class="col-lg-2"><img class="pdfImg" mat-card-image src="assets/img/pdf.png" /></div>
                    <div class="col-lg-9 mt-1 ml-2">
                        <div class="row reportName">{{r?.reportUrl.split('/').pop().replace('.pdf', '')}}
                        </div>
                        <div class="row spanContent mt-2">Assigned on: {{r?.assignedDate.split('T')[0]}}</div>
                    </div>
                </div>
                <div class="row flex mt-5">
                    <div class="col-sm-6"><a class="btn btnFirst btnLabel" data-toggle="modal"
                            data-target="#reportModal" (click)="viewReport(r.reportUrl)">View</a></div>
                    <div class="col-sm-6">
                        <a class="btn btnFirst btnLabel" (click)="reAssignFP(r.id)">Reassign</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="modal fade" id="reportModal" tabindex="-1" role="dialog" aria-labelledby="reportModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content modal-size-style">
            <div class="modal-header">
                <h5 class="modal-title" id="reportModalLabel">Fitness Report</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe id="frameStyle" [src]="modalDocumentURL" frameborder="0" width="100%" height="500px"></iframe>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>